<template>
  <div class="row-adv-response-container">
    <div class="wrapper" v-if="advResponseTime">
      <span class="wrapper_text">{{ advResponseTime }}</span>
      <div class="box" :style="{ width: (advResponseTime / maxAdvResponseTime) * 100 + '%' }">
        <span class="box_text">{{ advResponseTime }}</span>
      </div>
    </div>
    <span v-else>-</span>
  </div>
</template>

<script>
export default {
  props: {
    maxAdvResponseTime: {
      default: 0,
      type: Number
    },
    advResponseTime: {
      default: 0,
      type: Number
    }
  }
}
</script>

<style lang="less" scoped>
.row-adv-response-container {
  .wrapper {
    height: 14px;
    width: 100px;
    border-radius: 5px;
    background-color: #ddd;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    line-height: 14px;
    vertical-align: middle;
    .wrapper_text {
      display: inline-block;
      position: absolute;
      left: 45px;
      top: 0;
      color: #000;
      font-size: 12px;
    }
    .box {
      height: 100%;
      background: @primary-color;
      overflow: hidden;
      position: relative;
      box-sizing: border-box;
      border-radius: 5px;
      .box_text {
        display: inline-block;
        position: absolute;
        left: 45px;
        color: #fff;
        font-size: 12px;
      }
    }
  }
}
</style>
